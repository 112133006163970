.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prod .abstract-pic-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #a9a9a9ba;
  box-shadow: 0 0 2px 5px inset;
}

.prod .abstract-pic {
  width: calc(100vh - 50px);
  height: calc(100vh - 50px);
  margin: 25px;
  box-shadow: 0 0 5px 3px;
}

.prod .camera-container {
  position: absolute;
  width: 320px;
  height: 240px;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.canvas-wrapper {
  width: 100%;
  height: 100%;
}

#output {
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}

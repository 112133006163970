body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  white-space: pre-wrap;
  word-wrap: break-word;

}

.two-column-layout{
  display: flex;
}

.column{
  flex: 50%;
}

/*Generated Picture*/
.abstract-pic {
  width: 512px;
  height: 512px;
}

.arts{
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.arts > .abstract-pic-container {
  display: flex;
  flex-basis: calc(50% - 40px);
  justify-content: center;
  flex-direction: column;
}
.art {
    filter: contrast(2);
    position: absolute;
    transform: translate(-50%, -50%);
}

.art-1 {
    top: 50%;
    left: 50%;
    width: calc(30vw);
    height: calc(30vw);
}

.art-1-frame {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(40vw);
    height: calc(40vw);
    border-radius: 50%;
    color: black;
    z-index: 10;
}

.art-2 {
    top: 20%;
    left: 15%;
    width: calc(20vw);
    height: calc(20vw);
}

.art-3 {
    top: 20%;
    left: 85%;
    width: calc(20vw);
    height: calc(20vw);
}

.art-4 {
    top: 80%;
    left: 15%;
    width: calc(20vw);
    height: calc(20vw);
}

.art-5 {
    top: 85%;
    left: 85%;
    width: calc(20vw);
    height: calc(20vw);
}
